.navegacion{
    overflow-y: scroll;
    height: 100vh;
    padding: 20px; 
    width: 25%; 
    position: fixed;  
}

.rgpd{
    margin-left: 25%;
    background: #E1E1E1;
    padding: 0;

}

#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: rgba(155,155,155,.5);
}

@media screen and (max-width: 768px){
    .navegacion{
        position: relative;
        width: 100%;
        margin-top: 40px;
        height: auto;
        overflow: hidden;
    }
    .rgpd{
        margin-left: 0;
    }
}