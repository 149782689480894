:root {
  --bg-color: #2d313c;  
}

body {
  height: 100%;
  min-height: 100vh;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p, h1, h2, h3, h4, h5, h6, a {
  font-family: 'Roboto', sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.navbar_custom{
  color: #ffffff;
  background: #4D89BF;
}

.navbar_custom a{
  color: #ffffff;
}

label{
  color: #000;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

footer{
  position: relative;
  bottom: 0;
}

[class^=bmd-label] {
  color: rgba(0,0,0,.8);
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: hsla(0,0%,100%,1);
}

.navbar-dark .navbar-nav .nav-link {
  color: hsla(0,0%,100%,.7);
}